/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Layout, Button, Col, Image } from "antd";
import { Menu, Avatar, Space } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { isTab } from "../common/util";
import cx from "classnames";
import { useRecoilState } from "recoil";
import { offerPageState } from "../recoil/Atom";
import UserActions from "../actions";
import { Dropdown } from "antd";
import staticProfile from "../assets/images/staticProfile.png";
import DropdownIcon from "../assets/images/header-dropdown-icon.svg";
import hamburger from "../assets/images/hamburger.svg";
import wallet from "../assets/images/wallet.svg";
import homeIcon from "../assets/images/homeIcon.png";
import aboutUs from "../assets/images/aboutUs.png";
import { Actions } from "../common";
const { ROLE_FYNDR , LOG_OUT } = Actions;
console.log("!ROLE_FYNDR &&", ROLE_FYNDR);
const { Header } = Layout;
const TopMenu = (props) => {
  let history = useHistory();
  const ref = useRef();
  const { isMasquerade } = useSelector(({ admin }) => admin);
  const {
    indv,
    indv: { entityType },
  } = useSelector(({ auth }) => auth);
  const menu = (
    <Menu>
      <Menu.Item
        key="3"
        onClick={async () => {
          history.replace(indv?.indvid ? "/billing/transactions" : "/login");
          await setOfferPageSetting(null);
        }}
      >
        {indv?.indvid ? "Account" : "Login"}
      </Menu.Item>
      {!indv?.indvid && (
        <Menu.Item
          key="4"
          onClick={() => {
            history.push("/register");
          }}
        >
          Register
        </Menu.Item>
      )}
      {indv?.indvid && (
        <Menu.Item
          onClick={async () => {
            history.push("/myOffers")
          }}
        >
          My Offers
        </Menu.Item>
      )}
      {indv?.indvid && (
        <Menu.Item
          onClick={async () => {
            history.push("/");
            UserActions.logout(dispatch);
          }}
        >
          Log Out
        </Menu.Item>
      )}
    </Menu>
  );
  const [key, setKey] = useState(null);
  const [ishover, setIshover] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const { menuUp } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    location: { pathname },
  } = history;
  const [, setOfferPageSetting] = useRecoilState(offerPageState);
  const findPath = (path) => {
    let index = path.lastIndexOf("/");
    index = index === 0 ? path.length : index;
    return path.substr(0, index);
  };
  const checkPath = (pathname) => {
    let DirectRoutes = [
      "/",
      "/offer-details",
      "/register",
      "/qr",
      "/invoice-pay",
      "/voucher/",
      "/external",
    ];
    if (DirectRoutes.includes(findPath(pathname))) {
      setKey("1");
    } else {
      setKey("2");
    }
  };
  useEffect(() => {
    checkPath(pathname);
  }, [pathname]);

  useEffect(() => {
    const broadcastChannel = new BroadcastChannel("auth-channel");

    broadcastChannel.onmessage = (event) => {
      if (event.data.type === "logout") {
        dispatch({ type: LOG_OUT });
        localStorage.clear();
        window.location.reload();
      }
    };
    return () => {
      broadcastChannel.close();
    };
  }, [dispatch]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsMenuVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
  const headerClass = cx({
    // "header-right": true,
    liftMenu: menuUp && isTab(),
    "hover-menu": true,
  });
  const backgroundclass = cx({
    "bg-info": !props.catalogue && !isMasquerade,
    blueclass: props.catalogue,
    "bg-danger-fyndr": isMasquerade,
  });
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const handleMouseEnter = (key) => {
    setIshover(true);
    setActiveMenuItem(key);
  };
  const handleMouseLeave = (key) => {
    setIshover(false);
  };
  return (
    <>
      {!isTab() ? (
        <Header
          style={{ maxWidth: "100%", float: "right" }}
          className="app-header"
        >
          <div className={`app-header-inner ${backgroundclass} `}>
            <div style={{ maxWidth: "100%" }} className={headerClass}>
              <Col></Col>
              <Col>
                <Menu
                  activeKey={key}
                  className={`${backgroundclass}`}
                  mode="horizontal"
                >
                  {props.catalogue !== true && (
                    <a href="/"  rel="noopener noreferrer">
                    <Menu.Item
                      className="ManuItem"
                      style={{ fontSize: "1rem", color: "white" , padding: "0px 20px"}}
                      key="1"
                      onClick={() => {
                        history.push("/");
                      }}
                      onMouseEnter={() => handleMouseEnter("1")}
                      onMouseLeave={handleMouseLeave}
                    >

                      <Image
                        preview={false}
                        src={homeIcon}
                        style={{ width: "15px" }}
                        className="imgss"
                      />

                      {ishover && activeMenuItem === "1" && (
                        <span className="textss">Home</span>
                      )}
                    </Menu.Item>
                    </a>
                  )}
                  {indv.indvid && entityType !== ROLE_FYNDR && (
                    <Col>
                      {props.catalogue !== true && (
                      <a href="/wallet"  rel="noopener noreferrer">
                        <Menu.Item
                          className="ManuItem"
                          style={{
                            fontSize: "1rem",
                            borderBottom:
                              location.pathname === "/wallet"
                                ? "2px solid #fff"
                                : "",
                            height: "59px",
                          }}
                          key="2"
                          // className="app-header-menuitem"
                          onClick={() => {
                            history.push("/wallet");
                          }}
                          onMouseEnter={() => handleMouseEnter("2")}
                          onMouseLeave={handleMouseLeave}
                        >

                          <Image
                            preview={false}
                            src={wallet}
                            alt="Wallet"
                            style={{ width: "20px" }}
                            className="imgss"
                          />
                          {ishover && activeMenuItem === "2" && (
                            <span className="WalletTextss">Wallet</span>
                          )}
                        </Menu.Item>
                        </a>
                      )}
                    </Col>
                  )}
                  {props.catalogue !== true && (
                    <Col>
                    <a href="/aboutus"  rel="noopener noreferrer">
                      <Menu.Item
                        className="aboutUsMenu"
                        style={{
                          fontSize: "1rem",
                          borderBottom:
                            location.pathname === "/aboutus"
                              ? "2px solid #fff"
                              : "none",
                        }}
                        key="5"
                        onClick={() => {
                          history.push("/aboutus");
                        }}
                        onMouseEnter={() => handleMouseEnter("5")}
                        onMouseLeave={handleMouseLeave}
                      >

                        <Image
                          preview={false}
                          src={aboutUs}
                          style={{ width: "20px" }}
                          className="imgss"
                        />
                        {ishover && activeMenuItem === "5" && (
                          <span className="AboutTextss">About Us</span>
                        )}
                      </Menu.Item>
                      </a>
                    </Col>
                  )}
                  <Col>
                    {props.catalogue !== true && (
                      <a href="/offers"  rel="noopener noreferrer">
                      <Button
                        style={{
                          border: "0px solid #223369",
                          color: "#fff",
                          borderRadius: "5px",
                          border: "1px solid var(--white, #FFF)",
                        }}
                        type="primary"
                        className={
                          isMasquerade
                            ? "app-header_offer-btn bg-danger-fyndr"
                            : "app-header_offer-btn"
                        }
                        onClick={async () => {
                          //setting the recoil state to null so nothing from the home page is pass down to offer page
                          // if (
                          //   history.location.pathname === "/" ||
                          //   history.location.pathname === "/offer-listing"
                          // ) {
                          //   await setOfferPageSetting(null);
                          // }
                          history.push("/offers");
                        }}
                      >
                        Offers & Events
                      </Button>
                      </a>
                    )}
                  </Col>
                  <Dropdown overlay={menu} trigger={["click"]}>
                    <Space className={isMasquerade ? "avatarImgLogo" : ""}
                    // style={{marginRight:"40px" , position : isMasquerade ? "absolute" : "" , marginLeft : isMasquerade ? "23rem" : ""}}
                    >
                      <Avatar src={staticProfile} />
                      <img src={DropdownIcon} alt="drop-icon" />
                    </Space>
                  </Dropdown>
                </Menu>
              </Col>
            </div>
          </div>
        </Header>
      ) : (
        <Header style={{ maxWidth: "100%" }} className="app-header">
          <div className={`app-header-inner ${backgroundclass} `}>
            <div style={{ maxWidth: "100%" }} className={headerClass} ref={ref}>
              {props.catalogue !== true && (
                <img
                  src={hamburger}
                  className="header_menu"
                  alt="menu"
                  onClick={() => {
                    setIsMenuVisible(!isMenuVisible);
                  }}
                />
              )}
              {isMenuVisible && (
                <Menu
                  activeKey={key}
                  className="app-header-menu-item"
                  mode="vertical"
                // onClick={() => window.location.reload()}
                >
                  {props.catalogue !== true && (
                    <Menu.Item
                      // key="1"
                      onClick={() => {
                        history.push("/");
                      }}
                    >
                      Home
                    </Menu.Item>
                  )}
                  {indv.indvid && entityType !== ROLE_FYNDR && (
                    <Col align={"start"}>
                      {props.catalogue !== true && (
                        <Menu.Item
                          // key="1"
                          onClick={() => {
                            history.push("/wallet");
                          }}
                        >
                          Wallet
                        </Menu.Item>
                      )}
                    </Col>
                  )}
                  {props.catalogue !== true && (
                    <Menu.Item
                      // key="1"
                      onClick={() => {
                        history.push("/aboutus");
                      }}
                    >
                      About Us
                    </Menu.Item>
                  )}
                  {props.catalogue !== true && (
                    <>
                      <Menu.Item
                        // key="4"
                        onClick={async () => {
                          //setting the recoil state to null so nothing from the home page is pass down to offer page
                          // if (
                          //   history.location.pathname === "/" ||
                          //   history.location.pathname === "/offer-listing"
                          // ) {
                          //   await setOfferPageSetting(null);
                          // }
                          history.push("/offers");
                        }}
                      >
                        Offers
                      </Menu.Item>
                      <Menu.Item
                        key="3"
                        onClick={async () => {
                          history.replace(
                            indv?.indvid ? "/billing/transactions" : "/login"
                          );
                          await setOfferPageSetting(null);
                        }}
                      >
                        {indv?.indvid ? "Account" : "Login"}
                      </Menu.Item>
                      {!indv?.indvid && (
                        <Menu.Item
                          key="4"
                          onClick={() => {
                            history.push("/register");
                          }}
                        >
                          Register
                        </Menu.Item>
                      )}
                      {indv?.indvid && (
                        <Menu.Item
                          onClick={async () => {
                            history.push("/myOffers")
                          }}
                        >
                          My Offers
                        </Menu.Item>
                      )}
                      {indv?.indvid && (
                        <Menu.Item
                          onClick={async () => {
                            history.push("/");
                            console.log("Log Out button before dispatch");
                            await setOfferPageSetting(null);
                            UserActions.logout(dispatch);
                          }}
                        >
                          Log Out
                        </Menu.Item>
                      )}
                    </>
                  )}
                </Menu>
              )}
              {props.catalogue === true && (
                <Dropdown overlay={menu} trigger={["click"]}>
                  <Space>
                    <Avatar src={staticProfile} />
                    <img src={DropdownIcon} alt="drop-icon" />
                  </Space>
                </Dropdown>
              )}
            </div>
          </div>
        </Header>
      )}
    </>
  );
};
export default TopMenu;
